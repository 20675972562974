import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Navbar.module.scss";

export default function Navbar() {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return (
        <footer className={styles.navbar}>
            <section className={pathname == "/" || pathname.slice(1, 8) == "product" ? styles.active : ""} onClick={() => navigate("/")}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z" />
                </svg>
                <p>Menu</p>
            </section>
            <section className={pathname == "/chat" ? styles.active : ""} onClick={() => navigate("/chat")}>
                <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 17.1115C15 14.6984 13.3027 12.643 10.996 12.2627L10.7882 12.2284C8.94108 11.9239 7.05892 11.9239 5.21182 12.2284L5.00404 12.2627C2.69733 12.643 1 14.6984 1 17.1115C1 18.1545 1.81848 19 2.82813 19H13.1719C14.1815 19 15 18.1545 15 17.1115Z" stroke="white" strokeWidth="1.5" />
                    <path d="M3.91665 4.9375C3.91665 7.11212 5.74482 8.875 7.99998 8.875C10.2551 8.875 12.0833 7.11212 12.0833 4.9375C12.0833 2.76288 10.2551 1 7.99998 1C5.74482 1 3.91665 2.76288 3.91665 4.9375Z" stroke="white" strokeWidth="1.5" />
                </svg>
                <p>Chat</p>
            </section>
            <section className={pathname == "/cart" ? styles.active : ""} onClick={() => navigate("/cart")}>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1994_1520)">
                        <path d="M8.2526 20.1667C8.75887 20.1667 9.16927 19.7563 9.16927 19.25C9.16927 18.7438 8.75887 18.3334 8.2526 18.3334C7.74634 18.3334 7.33594 18.7438 7.33594 19.25C7.33594 19.7563 7.74634 20.1667 8.2526 20.1667Z" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M18.3307 20.1667C18.837 20.1667 19.2474 19.7563 19.2474 19.25C19.2474 18.7438 18.837 18.3334 18.3307 18.3334C17.8245 18.3334 17.4141 18.7438 17.4141 19.25C17.4141 19.7563 17.8245 20.1667 18.3307 20.1667Z" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M0.914062 0.916626H4.58073L7.0374 13.1908C7.12122 13.6128 7.35081 13.9919 7.68598 14.2617C8.02115 14.5315 8.44054 14.6749 8.87073 14.6666H17.7807C18.2109 14.6749 18.6303 14.5315 18.9655 14.2617C19.3006 13.9919 19.5302 13.6128 19.6141 13.1908L21.0807 5.49996H5.4974" stroke="white" strokeWidth="1.83333" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1994_1520">
                            <rect width="22" height="22" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <p>Cart</p>
            </section>
            <section className={location.pathname == "/bill" ? styles.active : ""} onClick={() => navigate("/bill")}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.7969 7L14.9981 7.47905C15.3229 8.25243 15.4045 9.10637 15.2321 9.92729C14.9691 11.1799 13.9719 12.1473 12.7119 12.3723L12.601 12.3921C12.2014 12.4635 11.7923 12.4635 11.3928 12.3921L11.2818 12.3723C10.0218 12.1473 9.02465 11.1799 8.7616 9.92729C8.58921 9.10637 8.67085 8.25243 8.99567 7.47905L9.19687 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M3.78151 15.143C4.35168 17.6504 6.30069 19.6204 8.79998 20.2251C10.9004 20.7334 13.1041 20.7334 15.2045 20.2251C17.7038 19.6204 19.6528 17.6504 20.223 15.143C20.7324 12.9026 20.6085 10.5648 19.8652 8.39084L19.7547 8.06787C18.9368 5.67585 16.8934 3.91013 14.4081 3.44766L13.7275 3.32102C12.5871 3.10881 11.4174 3.10881 10.277 3.32102L9.5964 3.44766C7.11106 3.91013 5.06771 5.67586 4.24976 8.06789L4.13933 8.39084C3.39596 10.5648 3.27207 12.9026 3.78151 15.143Z" stroke="white" strokeWidth="1.5" />
                </svg>
                <p>Bill</p>
            </section>
        </footer>
    );
};

