import axios from "axios";
import { ApiParams } from "./types";

export async function postOrder(uuid: string, params: ApiParams) {
    return await axios.post(`https://restaurant-api2.hvala.tips/api/order/${uuid}`,
        params,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })
}

export async function fetchMenu(uuid: string) {
    return await axios.get(
        `https://restaurant-api2.hvala.tips/api/menu/${uuid}`
      )
}

export async function fetchTables(uuid: string) {
    return await axios.get(
        `https://restaurant-api2.hvala.tips/api/table/${uuid}`
      )
}

export async function fetchBill(uuid: string, orderId: string) {
    return await axios.get(
        `https://restaurant-api2.hvala.tips/api/order/${uuid}/${orderId}`
      )
}