import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiData, productFitlerPayload, ProductSliceState, Status } from "./types";

const initialState: ProductSliceState = {
    filteredProducts: [],
    products: [],
    categories: [],
    uuid: '',
    status: Status.LOADING,
};

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        setItems(state, action: PayloadAction<ApiData>) {
            state.categories = action.payload.categories;
            state.products = action.payload.products.map(product => {
                product.searchIndex = product.title.toLowerCase();
                return product
            });
            state.filteredProducts = state.products.sort((a, b) => (b.imgBase64 ? 1 : 0) - (a.imgBase64 ? 1 : 0));
        },
        setStatus(state, action: PayloadAction<Status>) {
            state.status = action.payload;
        },
        filterItems(state, action: PayloadAction<productFitlerPayload>) {
            state.filteredProducts = state.products;
            if (action.payload.categoryId) {
                state.filteredProducts = state.filteredProducts.filter(product => product.categoryId === action.payload.categoryId);
            }
            if (action.payload.searchValue) {
                const query = action.payload.searchValue.toLowerCase();
                state.filteredProducts = state.filteredProducts.filter(product => product.searchIndex.includes(query));
            }
        },
        addUuid(state, action: PayloadAction<string | null>) {
            state.uuid = action.payload;
        },
    }
});

export const { setItems, addUuid, filterItems, setStatus } = productSlice.actions;

export default productSlice.reducer;
