import React from "react";
import { setSearchValue } from "../../redux/filter/slice";
import styles from "./Search.module.scss";
import { useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { selectFilter } from "../../redux/filter/selectors";

export default function Search() {
    const dispatch = useAppDispatch();
    const { searchValue } = useSelector(selectFilter);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const onClickClear = () => {
        dispatch(setSearchValue(''))
        inputRef.current?.focus();
    };

    const updateSearchValue = (value: string) => {
        dispatch(setSearchValue(value));
    }

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateSearchValue(event.target.value);
    };

    return (
        <section className={styles.search}>
            <input placeholder="Search" ref={inputRef} value={searchValue} onChange={onChangeInput} />
            <svg className={styles.glass} width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="11.7698" cy="11.7666" r="8.98856" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18.0234 18.4851L21.5475 22" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            {searchValue && (
                <svg onClick={onClickClear} className={styles.cross}
                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
                </svg>
            )}
        </section>
    );
};
