import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BillItem, BillSliceState } from "./types";

const BILLKEY = "bill";

const getBillFromStorage = () => {
    const data = localStorage.getItem(BILLKEY);
    const items = data ? JSON.parse(data) : [];
    const amount = 0;
    const billNumber = 0;
    const createdAt = '';
    const tipsUrl = '';

    return {
        amount: amount as number,
        items: items as BillItem[],
        billNumber: billNumber as number,
        createdAt: createdAt as string,
        tipsUrl: tipsUrl as string,
    }
}

// const setBillToStorage = (items: BillItem[]) => {
//     const json = JSON.stringify(items);
//     localStorage.setItem(BILLKEY, json);
// }

const initialState: BillSliceState = getBillFromStorage();

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addBillNumber(state, action: PayloadAction<number>) {
            state.billNumber = action.payload;
        },
        addBill(state, action: PayloadAction<any>) {
            state.amount = action.payload.data.amount;
            state.createdAt = action.payload.data.createdAt;
            state.items = action.payload.data.items;
            state.tipsUrl = action.payload.tips_url;
        },
    }
});

export const { addBillNumber, addBill } = cartSlice.actions;

export default cartSlice.reducer;
