import styles from "./BillItem.module.scss"

type BillItemProps = {
  imgBase64: null;
  price: number;
  productCode: string;
  quantity: number;
  title: string;
}

export default function BillItemBlock({ title, quantity, price, imgBase64 }: BillItemProps) {
  return (
    <li className={styles.food}>
      <section className={styles.description}>
        <div className={styles.food_img}>
          <img src={imgBase64 ?? "/sample-product.png"} alt={title} />
        </div>
        <div className={styles.text}>
          <div className={styles.name}>
            {title}
          </div>
          <div className={styles.category}>
            Salad
          </div>
        </div>
      </section>
      <section className={styles.controls}>
        <p className={styles.summ}>
          {price * quantity} din.
        </p>
        <div className={styles.paid_status}>
          not paid
        </div>
      </section>
    </li>
  )
}
