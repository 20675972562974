export type ProductResponse = {
    data: Product[];
}

export type Product = {
    id: number;
    categoryId: number;
    code: string;
    description: string;
    imgBase64: string | null;
    isForSale: boolean;
    price: number;
    title: string;
    searchIndex: string;
}

export type Category = {
    id: number;
    isActive: boolean;
    title: string;
}

export type productFitlerPayload = {
    categoryId: number;
    searchValue: string;
}

export enum Status {
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface ProductSliceState {
    categories: Category[];
    filteredProducts: Product[];
    products: Product[];
    status: Status;
    uuid: string | null;
}

export interface ApiData {
    products: Product[];
    categories: Category[];
}
