import styles from "./CartItem.module.scss"
import { addItem, minusItem } from "../../redux/cart/slice"
import { useAppDispatch } from "../../redux/store"
import { useSelector } from "react-redux"
import { selectCartItemById } from "../../redux/cart/selectors"

type CartItemProps = {
  id: number,
  imgBase64: string | null,
  title: string,
  categoryTitle: string,
  price: number,
  count: number,
}

export default function CartItemBlock({ id, imgBase64, title, count, categoryTitle, price }: CartItemProps) {
  const dispatch = useAppDispatch();
  const cartItem = useSelector(selectCartItemById(id));

  const onClickPlus = () => {
    if (cartItem) {
      dispatch(addItem(cartItem));
    }
  }

  const onClickMinus = () => {
    dispatch(minusItem(id))
  }

  return (
    <li key={id} className={styles.food}>
      <section className={styles.description}>
        <div className={styles.food_img}>
          <img src={imgBase64 ?? "/sample-product.png"} alt={title} />
        </div>
        <div className={styles.text}>
          <div className={styles.name}>
            {title}
          </div>
          <div className={styles.category}>
            {categoryTitle}
          </div>
        </div>
      </section>
      <section className={styles.controls}>
        <div className={styles.buttons}>
          <button onClick={onClickMinus}>
            <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0" y="3" width="7" height="1" fill="#6B7280" />
            </svg>
          </button>
          <span>{count}</span>
          <button onClick={onClickPlus}>
            <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0" y="3" width="7" height="1" fill="#6B7280" />
              <rect x="3" y="0" width="1" height="7" fill="#6B7280" />
            </svg>
          </button>
        </div>
        <div className={styles.price}>
          {price * count} din.
        </div>
      </section>
    </li>
  )
}
