import styles from "./FullProduct.module.scss"
import { useSelector } from "react-redux";
import { selectProductData } from "../../redux/product/selectors";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../redux/store";
import { selectCartItemById } from "../../redux/cart/selectors";
import { addItem, minusItem } from "../../redux/cart/slice";
import { CartItem } from "../../redux/cart/types";
import { useEffect } from "react";

export default function FullProduct() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { products, categories } = useSelector(selectProductData);
  const { id } = useParams();
  if (!id) {
    return (<h1>No product</h1>)
  }
  const productId = parseInt(id);
  const product = products.find(item => item.id === productId);
  const category = categories.find(c => c.id === product?.categoryId);
  const cartItem = useSelector(selectCartItemById(productId));

  const onClickAdd = () => {
    if (!product) { return; }
    const item: CartItem = {
      id: product.id,
      imgBase64: product.imgBase64,
      title: product.title,
      price: product.price,
      code: product.code,
      categoryTitle: category?.title ? category.title : '',
      count: 0
    };
    dispatch(addItem(item));
  }

  const onClickMinus = () => {
    dispatch(minusItem(productId))
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  if (!product) {
    return (<h1>Loading</h1>)
  }

  return (
    <article className={styles.foodPage}>
      <section className={styles.cover}>
        <button onClick={() => navigate(-1)}>
          <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 6.5H14.5M1 6.5L6.5 1M1 6.5L6.5 11.5" stroke="#575759" />
          </svg>
        </button>
        <h1 className={styles.details}>Details</h1>
        <div className={styles.food_img}>
          <img src={product.imgBase64 ?? "/sample-product.png"} alt={product.title} />
        </div>
      </section>
      <section className={styles.namequantity}>
        <div>
          <h2>{product.title}</h2>
          <span className={styles.category}>{category?.title}</span>
        </div>
        <div className={styles.controls}>
          <button onClick={onClickMinus} disabled={cartItem?.count === 0}>
            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0" y="5" width="11" height="1" fill="#6B7280" />
            </svg>
          </button>
          <span>{cartItem?.count ? cartItem.count : 0}</span>
          <button onClick={onClickAdd}>
            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0" y="5" width="11" height="1" fill="#6B7280" />
              <rect x="5" y="0" width="1" height="11" fill="#6B7280" />
            </svg>
          </button>
        </div>
      </section>
      {product.description && (
        <section className={styles.description}>
          <p>{product.description}</p>
        </section>
      )}
      <section className={styles.purchase}>
        <div className={styles.price}>
          {product.price} din.
        </div>
        {cartItem && cartItem.count > 0 ?
          <button onClick={() => navigate("/cart")}>
            {cartItem.count} items in cart
          </button> :
          <button onClick={onClickAdd}>
            Add to cart
          </button>}
      </section>
    </article>
  );
};
