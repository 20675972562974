import { useDispatch } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import product from "./product/slice";
import filter from "./filter/slice";
import cart from "./cart/slice";
import bill from "./bill/slice";

export const store = configureStore({
  reducer: { product, filter, cart, bill },
});

export type RootState = ReturnType<typeof store.getState>

type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()