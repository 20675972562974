import ContentLoader from "react-content-loader"

const Skeleton = () => (
  <ContentLoader
    speed={2}
    width={300}
    height={20}
    viewBox="0 0 300 20"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="10" ry="10" width="90" height="17" />
    <rect x="100" y="0" rx="10" ry="10" width="90" height="17" />
    <rect x="200" y="0" rx="10" ry="10" width="90" height="17" />
  </ContentLoader>
)
export default Skeleton
