import { useSelector } from "react-redux";
import { selectProductData } from "../../redux/product/selectors";
import { useAppDispatch } from "../../redux/store";
import { setCategoryId } from "../../redux/filter/slice";
import { selectFilter } from "../../redux/filter/selectors";
import styles from "./Categories.module.scss";
import Skeleton from "./Skeleton";

export default function Categories() {
  const { categories } = useSelector(selectProductData);
  const { categoryId } = useSelector(selectFilter);

  const dispatch = useAppDispatch();
  const onChangeCategory = (id: number) => {
    dispatch(setCategoryId(id));
  }

  if (!categories.length) {
    return <Skeleton />
  }

  return (
    <ul className={styles.categories}>
      <li key={0}
        onClick={() => onChangeCategory(0)}
        className={categoryId == 0 ? styles.active : ''}>
        All
      </li>

      {categories.map((category) =>
        category.isActive &&
        <li key={category.id}
          onClick={() => onChangeCategory(category.id)}
          className={categoryId == category.id ? styles.active : ''}>
          {category.title}
        </li>)}
    </ul >)
}
