import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { fetchMenu, fetchTables } from "./api/actions";
import { setTable } from "./redux/cart/slice";
import { TableItem } from "./redux/cart/types";
import { useAppDispatch } from "./redux/store";
import { addUuid, setItems, setStatus } from "./redux/product/slice";
import { Status } from "./redux/product/types";
import MainLayout from "./layouts/MainLayout";
import Home from "./pages/Home";
import Cart from "./pages/Cart";
import Bill from "./pages/Bill";
import FullProduct from "./pages/FullProduct";
import NotFound from "./pages/NotFound";
import Chat from "./pages/Chat";

export default function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      const uuid = localStorage.getItem("uuid");
      dispatch(addUuid(uuid));
      const tableId = parseInt('' + localStorage.getItem("table"));
      if (uuid) {
        fetchMenu(uuid).then((response) => {
          dispatch(setItems(response.data.data));
          dispatch(setStatus(Status.SUCCESS));
        }).catch(function (error) {
          console.warn(error);
          dispatch(setStatus(Status.ERROR));
        });

        fetchTables(uuid).then((response) => {
          if (tableId) {
            const table = response.data.data.find((table: TableItem) => table.id === tableId);
            dispatch(setTable(table));
          }
        }).catch(function (error) {
          console.warn(error);
          dispatch(setStatus(Status.ERROR));
        });
      } else {
        dispatch(setStatus(Status.ERROR));
      }
    })();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="" element={<Home />} />
        <Route path="cart" element={<Cart />} />
        <Route path="chat" element={<Chat />} />
        <Route path="bill" element={<Bill />} />
        <Route path="product/:id" element={<FullProduct />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
