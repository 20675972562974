import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/store";
import { Link } from "react-router-dom";
import { selectCartItemById } from "../../redux/cart/selectors";
import { CartItem } from "../../redux/cart/types";
import { addItem } from "../../redux/cart/slice";
import styles from "./ProductBlock.module.scss"
import { selectProductData } from "../../redux/product/selectors";

type ProductProps = {
  id: number,
  title: string,
  code: string,
  price: number;
  categoryId: number,
  isForSale: boolean,
  imgBase64: string | null,
}

export default function ProductBlock({ id, title, price, code, isForSale, categoryId, imgBase64 }: ProductProps) {
  const dispatch = useAppDispatch();
  const cartItem = useSelector(selectCartItemById(id));
  const { categories } = useSelector(selectProductData);
  const category = categories.find(c => c.id === categoryId);

  const addedCount = cartItem ? cartItem.count : 0;

  const onClickAdd = () => {
    const item: CartItem = {
      id,
      imgBase64,
      title,
      price,
      code,
      categoryTitle: '' + category?.title,
      count: 0
    };
    dispatch(addItem(item));
  }

  return (
    <>{isForSale && (
      <article className={styles.food}>
        <Link to={`/product/${id}`} className={styles.food_img}>
          <img src={imgBase64 ?? "/sample-product.png"} alt={title} />
        </Link>
        <h2>{title}</h2>
        <p className={styles.category}>{category?.title}</p>
        <div className={styles.cartprice}>
          <div className={styles.price}>{price} din.</div>
          <button type="button" className={addedCount > 0 ? styles.fullcart : styles.cart} onClick={onClickAdd}>

            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="5.89062" y1="0.75" x2="5.89062" y2="11.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
              <line x1="0.75" y1="6.10718" x2="11.25" y2="6.10718" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
            {addedCount > 0 && <div className={styles.quantity}> {addedCount} </div>}

          </button>
        </div>
      </article>
    )}</>
  );
}
