import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CartItem, CartSliceState, TableItem } from "./types";

const CARTKEY = "cart";

const getCartFromStorage = () => {
    const data = localStorage.getItem(CARTKEY);
    const items = data ? JSON.parse(data) : [];

    const table = undefined;

    const totalPrice = calcTotalPrice(items);

    return {
        items: items as CartItem[],
        table: table as TableItem | undefined,
        totalPrice
    }
}

const setCartToStorage = (items: CartItem[]) => {
    const json = JSON.stringify(items);
    localStorage.setItem(CARTKEY, json);
}

const calcTotalPrice = (items: CartItem[]) => {
    return items.reduce((sum, obj) => obj.price * obj.count + sum, 0);
}

const initialState: CartSliceState = getCartFromStorage();

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addItem(state, action: PayloadAction<CartItem>) {
            const findItem = state.items.find(obj => obj.id === action.payload.id);

            if (findItem) {
                findItem.count++;
            } else {
                state.items.push({
                    ...action.payload,
                    count: 1
                })
            }

            state.totalPrice = calcTotalPrice(state.items);
            setCartToStorage(state.items);
        },
        minusItem(state, action: PayloadAction<number>) {
            const findItem = state.items.find(obj => obj.id === action.payload);

            if (findItem && findItem.count > 0) {
                findItem.count--;
            }
            if (findItem && findItem.count === 0) {
                state.items = state.items.filter((obj) => obj.id !== findItem.id);
            }
            state.totalPrice = calcTotalPrice(state.items);
            setCartToStorage(state.items);
        },
        removeItem(state, action: PayloadAction<number>) {
            state.items = state.items.filter((obj) => obj.id !== action.payload);
            state.totalPrice = calcTotalPrice(state.items);
            setCartToStorage(state.items);
        },
        setTable(state, action: PayloadAction<TableItem | undefined>) {
            state.table = action.payload;
        },
        clearItems(state) {
            state.items = [];
            state.totalPrice = 0;
            localStorage.removeItem(CARTKEY);
        },
    },
});

export const { addItem, minusItem, removeItem, setTable, clearItems } = cartSlice.actions;

export default cartSlice.reducer;
