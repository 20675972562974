import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux/es/exports";
import { useAppDispatch } from "../../redux/store";
import { selectFilter } from "../../redux/filter/selectors";
import { filterItems } from "../../redux/product/slice";
import { selectProductData } from "../../redux/product/selectors";
import { Product, Status } from "../../redux/product/types";
import Categories from "../../components/Categories";
import NoProductsBlock from "../../components/NoProductsBlock";
import ProductBlock from "../../components/ProductBlock";
import ScanQrAgain from "../../components/ScanQrAgain";
import Search from "../../components/Search";
import Skeleton from "../../components/ProductBlock/Skeleton";
import styles from "./Home.module.scss"
import { clearItems } from "../../redux/cart/slice";

export default function Home() {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    //clear uuid and table from URL
    if (searchParams.get("uuid") || searchParams.get("table")) {
      localStorage.setItem("uuid", '' + searchParams.get("uuid"));
      localStorage.setItem("table", '' + searchParams.get("table"));
      dispatch(clearItems());
      navigate('/');
    }
  }, []);

  //filter items
  const { categoryId, searchValue } = useSelector(selectFilter);
  React.useEffect(() => {
    dispatch(filterItems({ categoryId: categoryId, searchValue: searchValue }))
  }, [categoryId, searchValue]);

  const { filteredProducts, status } = useSelector(selectProductData);
  const items = filteredProducts.map((obj: Product) => (<ProductBlock key={obj.id} {...obj} />));
  const skeletons = [...new Array(16)].map((_, index) => (<Skeleton key={index} />));

  if (status === Status.ERROR) {
    return <ScanQrAgain />;
  }

  return (
    <div className={styles.home}>
      <header>
        <h1>Menu</h1>
      </header>
      <Search />
      <Categories />
      <section className={styles.content}>
        {status === Status.LOADING ? skeletons : (items.length ? items : <NoProductsBlock/>)}
      </section>
    </div>
  );
};
