import { Link } from "react-router-dom";
import styles from "./CartEmpty.module.scss"

export default function CartEmpty() {
    return (
        <section className={styles.cart_empty}>

            <svg width="58" height="50" viewBox="0 0 58 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M50.6094 32.3776L54.4407 11.43C54.4495 11.3804 54.4283 11.3552 54.3769 11.3543C51.1039 11.326 47.83 11.3233 44.5551 11.3463C42.4144 11.3609 42.5233 8.5735 44.3971 8.5735C48.0314 8.57438 51.6647 8.57571 55.2972 8.57748C55.816 8.57748 56.2047 8.63812 56.4632 8.75941C57.4579 9.22554 57.4884 10.1206 57.2998 11.1551C55.947 18.567 54.5912 25.9785 53.2322 33.3896C52.9693 34.8251 52.4195 35.2341 51.0105 35.2341C39.4251 35.2332 27.8398 35.2332 16.2545 35.2341C15.532 35.235 15.0451 35.1859 14.7937 35.0867C14.2572 34.876 13.9305 34.4502 13.8136 33.8092C12.097 24.4593 10.3856 15.1081 8.67963 5.75551C8.51053 4.82946 8.32639 4.20929 8.12719 3.895C7.47249 2.86581 6.51634 2.76887 5.26538 2.77817C4.00114 2.78879 2.73645 2.79809 1.47133 2.80606C-0.498077 2.81934 -0.504717 -0.00927303 1.53772 2.28624e-05C3.07819 0.00710545 4.61821 0.0102045 6.15779 0.00931922C6.91739 0.00931922 7.50525 0.0677505 7.92135 0.184613C9.19976 0.54494 10.1262 1.30986 10.7008 2.47937C10.9018 2.88928 11.0837 3.53999 11.2466 4.43151C11.4963 5.79756 11.7459 7.15653 11.9956 8.50842C12.0045 8.55269 12.0315 8.57527 12.0766 8.57615C15.841 8.57792 19.5988 8.57748 23.3499 8.57482C24.8545 8.57482 25.2237 10.7806 23.8027 11.2587C23.6398 11.3127 23.3353 11.3397 22.8891 11.3397C19.4638 11.3397 16.0256 11.3401 12.5746 11.341C12.5667 11.3411 12.5589 11.3429 12.5518 11.3462C12.5447 11.3496 12.5384 11.3544 12.5333 11.3603C12.5283 11.3662 12.5246 11.3732 12.5225 11.3806C12.5205 11.3881 12.5201 11.3958 12.5215 11.4034L16.358 32.3763C16.3695 32.4409 16.4085 32.4732 16.4749 32.4732L50.4939 32.4746C50.5213 32.4747 50.5479 32.465 50.569 32.4473C50.5902 32.4296 50.6045 32.4049 50.6094 32.3776Z" fill="#02964C" />
                <path d="M32.6313 21.0021C32.6354 21.0064 32.6407 21.0094 32.6465 21.0106C32.6524 21.0118 32.6584 21.0113 32.6639 21.009C32.6694 21.0066 32.6741 21.0027 32.6773 20.9977C32.6805 20.9926 32.682 20.9868 32.6818 20.9808C32.6853 14.6463 32.6849 8.31847 32.6805 1.99726C32.6805 1.63073 32.7265 1.35451 32.8186 1.16859C33.4122 -0.0199534 35.1332 0.476713 35.1319 1.80337C35.1257 8.19541 35.1262 14.5874 35.1332 20.9795C35.1332 21.0282 35.1505 21.0353 35.185 21.0007C37.2974 18.9025 38.6458 17.5542 39.2301 16.9557C39.6178 16.5599 40.0747 16.3833 40.6165 16.552C41.3402 16.7764 41.659 17.7339 41.2553 18.3554C41.2154 18.4165 40.9905 18.652 40.5806 19.0619C38.9587 20.6856 37.3355 22.3079 35.7109 23.9289C35.0717 24.5673 34.6994 24.917 34.5941 24.978C33.7428 25.4734 33.1067 24.9555 32.4666 24.3101C30.6694 22.4987 28.8665 20.6948 27.0578 18.8985C26.4974 18.3408 26.0684 17.7432 26.581 17.0301C27.1135 16.289 27.9834 16.3448 28.6022 16.9703C29.9399 18.3248 31.283 19.6688 32.6313 21.0021Z" fill="#02964C" />
                <path d="M27.9483 44.3799C27.9483 45.8701 27.3564 47.2992 26.3027 48.353C25.249 49.4067 23.8198 49.9986 22.3296 49.9986C20.8395 49.9986 19.4103 49.4067 18.3566 48.353C17.3029 47.2992 16.7109 45.8701 16.7109 44.3799C16.7109 42.8898 17.3029 41.4606 18.3566 40.4069C19.4103 39.3532 20.8395 38.7612 22.3296 38.7612C23.8198 38.7612 25.249 39.3532 26.3027 40.4069C27.3564 41.4606 27.9483 42.8898 27.9483 44.3799ZM25.2459 44.3799C25.2459 43.6061 24.9385 42.864 24.3914 42.3169C23.8442 41.7697 23.1021 41.4624 22.3283 41.4624C21.5545 41.4624 20.8124 41.7697 20.2653 42.3169C19.7181 42.864 19.4107 43.6061 19.4107 44.3799C19.4107 45.1537 19.7181 45.8958 20.2653 46.443C20.8124 46.9901 21.5545 47.2975 22.3283 47.2975C23.1021 47.2975 23.8442 46.9901 24.3914 46.443C24.9385 45.8958 25.2459 45.1537 25.2459 44.3799Z" fill="#02964C" />
                <path d="M50.201 44.3799C50.201 45.8704 49.6089 47.2999 48.5549 48.3539C47.501 49.4078 46.0715 50 44.581 50C43.0904 50 41.661 49.4078 40.607 48.3539C39.553 47.2999 38.9609 45.8704 38.9609 44.3799C38.9609 42.8894 39.553 41.4599 40.607 40.406C41.661 39.352 43.0904 38.7599 44.581 38.7599C46.0715 38.7599 47.501 39.352 48.5549 40.406C49.6089 41.4599 50.201 42.8894 50.201 44.3799ZM47.4972 44.3799C47.4972 43.6068 47.1901 42.8654 46.6435 42.3188C46.0968 41.7721 45.3554 41.465 44.5823 41.465C43.8092 41.465 43.0678 41.7721 42.5211 42.3188C41.9745 42.8654 41.6674 43.6068 41.6674 44.3799C41.6674 45.153 41.9745 45.8944 42.5211 46.4411C43.0678 46.9877 43.8092 47.2948 44.5823 47.2948C45.3554 47.2948 46.0968 46.9877 46.6435 46.4411C47.1901 45.8944 47.4972 45.153 47.4972 44.3799Z" fill="#02964C" />
            </svg>

            <h2>Cart is empty</h2>

            <Link className={styles.go_to_menu} to="/">
                Go to menu
            </Link>
        </section>
    )
}