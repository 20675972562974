import { useSelector } from "react-redux";
import { selectCart } from "../../redux/cart/selectors.ts";
import styles from "./Cart.module.scss"
import CartItemBlock from "../../components/CartItem/index.tsx";
import CartEmpty from "../../components/CartEmpty/index.tsx";
import { selectProductData } from "../../redux/product/selectors.ts";
import { Link, useNavigate } from "react-router-dom";
import { postOrder } from "../../api/actions.ts";
import { useAppDispatch } from "../../redux/store.ts";
import { clearItems } from "../../redux/cart/slice.ts";
import { CartItem } from "../../redux/cart/types.ts";
import { addBillNumber } from "../../redux/bill/slice.ts";
import { selectBill } from "../../redux/bill/selectors.ts";

export default function Cart() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { table, totalPrice, items } = useSelector(selectCart);
  const { tipsUrl } = useSelector(selectBill);
  const { uuid } = useSelector(selectProductData);

  const placeOrder = async () => {
    if (totalPrice && uuid && table) {
      const data = items.map(item => {
        return {
          "product": {
            "id": item.id,
            "title": item.title,
            "code": item.code,
            "price": item.price,
          },
          "quantity": item.count
        }
      });
      postOrder(uuid, { "table_title": table.title, "data": data }).then(function (response) {
        if (response.data.order_id) {
          dispatch(addBillNumber(response.data.order_id));
          dispatch(clearItems());
          navigate('/bill');
          return;
        }
        alert('smth goes wrong, try again');
      }).catch(function (error) {
        console.warn(error);
        alert('smth goes wrong, try again');
      });
    }
  }

  if (!totalPrice) {
    return <CartEmpty />
  }

  return (
    <article className={styles.cart}>
      {table ? <h1>Order from the table N {table.title}</h1> : <h1>Scan QR to select table</h1>}
      <ul className={styles.order}>
        {items.map((item: CartItem) => < CartItemBlock key={item.id} {...item} />)}
      </ul>
      <button className={styles.placeOrder} onClick={placeOrder} disabled={!table}>
        Place order
      </button>
      <Link className={styles.tips_btn} to={tipsUrl ? tipsUrl : "https://www.hvala.tips/demo/demo_choosing_waiter/"}>
        Tips by card
      </Link>
    </article>
  );
};
